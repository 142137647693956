import { Injectable } from '@angular/core';

import { HEALTH_HISTORY_ADULT_SURVEY_SYSTEM_NAME } from '@app/core/global-constants';
import { SurveySystemNames } from '@app/survey/survey-models';
import { appendQueryParamsToPath } from '@app/utils/link.utils';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  // Emails
  readonly adminEmail = 'mailto:admin@onemedical.com';
  readonly maEmail = 'mailto:ma@onemedical.com';
  readonly techsupportEmail = 'mailto:techsupport@onemedical.com';

  // Patient UI Paths
  readonly home = `/`;
  readonly login = '/login';
  readonly forgotPassword = '/forgot_password';
  readonly membershipRenewConsumer = `/membership/renew/consumer`;
  readonly membershipRenewEnterprise = `/membership/renew/enterprise`;
  readonly membershipRenewOMNow = `/membership/renew/omnow`;
  readonly prepaidConfirmation = `/membership/prepaid/confirmation`;
  readonly prepaidInvite = `/membership/prepaid/invite`;
  readonly prepaidInviteChild = `/membership/prepaid/invite/child`;
  readonly membershipSettings = `/membership/settings`;
  readonly amazonPayManageMemberships = '/membership/manage';
  readonly verifyEmail = '/membership/verify-email';
  readonly registrationEnterprise = `/registration/enterprise`;
  readonly registrationExpedited = `/register/expedited`;
  readonly registrationFamily = `/registration/family`;
  readonly registrationPediatric = `/registration/pediatric`;
  readonly registrationPlans = `/registration/plans`;
  readonly registrationPaid = `/registration/paid`;
  readonly registrationError = `/registration/error`;
  readonly registrationConsumer = `/register`;
  readonly prepaidEnrollmentConversion = `/registration/paid/conversion`;
  readonly newAppointment = `/appointments/new`;
  readonly requestRecords = '/account/request-records';
  readonly notificationSettings = '/account/notification-settings';
  readonly healthInformationExchange = '/account/health-information-exchange';
  readonly profile = '/account/profile';
  readonly connectedAccounts = '/account/connected-accounts';
  readonly security = '/account/security';
  readonly family = '/account/family';
  readonly fsaHsaReimbursement = this.survey(SurveySystemNames.FSA_HSA_REIMBURSEMENT);
  readonly healthSurvey = this.survey(HEALTH_HISTORY_ADULT_SURVEY_SYSTEM_NAME);
  readonly medicalRecordsRequest = this.survey(SurveySystemNames.MEDICAL_RECORDS_REQUEST);
  readonly obtainMedicalRecords = this.survey(SurveySystemNames.OBTAIN_MEDICAL_RECORDS);
  readonly releaseMedicalRecords = this.survey(SurveySystemNames.RELEASE_MEDICAL_RECORDS);
  readonly medicalRecordsFamilyAuthorization = this.survey(SurveySystemNames.MEDICAL_RECORDS_FAMILY_AUTHORIZATION);
  readonly mentalHealthTopics = '/appointments/mental-health';
  readonly travelSurvey = this.survey(SurveySystemNames.TRAVEL);
  readonly referrals = '/registration/referrals';
  readonly processAfterpay = `${window.location.origin}/register`;
  readonly myProvider = '/account/my-provider';
  readonly selectProvider = '/providers/select-provider';
  readonly providerHub = '/providers/provider-hub';
  readonly onboarding = '/onboarding';
  readonly appStoreRedirect = '/app-store-redirect';
  readonly appointmentsPage = '/appointments/list';
  readonly appointmentReason = '/appointments/reason';
  readonly tasks = '/tasks';
  readonly messages = '/messages';
  readonly help = '/help';
  readonly getCare = '/get-care';
  readonly appointmentsGetCare = 'appointments/get-care';
  readonly guest = '/guest';
  readonly programUnavailable = '/appointments/unavailable';
  readonly prescriptionRenewal = '/prescription/renew';
  readonly accountSecurity = '/account/security';
  readonly enableMfa = `${this.accountSecurity}/mfa/enable`;
  readonly cancelMembership = '/membership/cancel';

  // External links
  readonly afterpay = 'https://portal.afterpay.com/us/login-email';
  readonly COVID_RESOURCES = 'https://onemedical.com/coronavirus';
  readonly SERVICES = 'https://www.onemedical.com/services';
  readonly INSURANCE_CHECKER =
    'https://www.onemedical.com/insurance/insurance-finder/?location=all-locations&carrier=all-carriers';

  survey(surveySystemName: string): string {
    return `/survey/${surveySystemName}`;
  }

  appointmentConfirmation(id: string): string {
    return `/appointments/${id}/confirmation`;
  }

  employeeIdRegistration(b2bCompanyId: number): string {
    return `${this.registrationEnterprise}/employee_id?b2b_company_id=${b2bCompanyId}`;
  }

  loginWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.login, queryParams);
  }

  onboardingWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.onboarding, queryParams);
  }

  registrationPediatricWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.registrationPediatric, queryParams);
  }

  registrationEnterpriseWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.registrationEnterprise, queryParams);
  }
}

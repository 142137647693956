import { ENVIRONMENT_TRAINING } from './training';

export const environment = {
  ...ENVIRONMENT_TRAINING,
  apiServer: 'https://training-app.1life.com',
  myoneServer: 'https://training-app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-training',
  },
  designSystemUrl: '/',
  marketingSite: 'https://mktg-stage.onemedical.com',
  opsUrl: 'https://staging.patientops.com',
  topLevelDomain: 'training-app.1life.com'
  
};
